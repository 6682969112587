:root {
  --bgGray: #7b7c7c;
  --bgRed: #cf2e26;
  --borderRed: #cf2e26;
}
/* You can add global styles to this file, and also import other style files */
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('roboto-v20-latin-300.woff2') format('woff2'),
  url('roboto-v20-latin-300.woff') format('woff');
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('roboto-v20-latin-regular.woff2') format('woff2'),
  url('roboto-v20-latin-regular.woff') format('woff');
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('roboto-v20-latin-500.woff2') format('woff2'),
  url('roboto-v20-latin-500.woff') format('woff');
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('roboto-v20-latin-700.woff2') format('woff2'),
  url('roboto-v20-latin-700.woff') format('woff');
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('roboto-v20-latin-900.woff2') format('woff2'),
  url('roboto-v20-latin-900.woff') format('woff');
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
button:focus { outline: none }
/** dashboard */
.backgroundImage { width: 52px; height: 80px; background-size: contain; background-position: center center; background-repeat: no-repeat; }
.backgroundImageEditPage { width: auto; height: 300px; background-size: contain; background-position: center center; background-repeat: no-repeat; }
.backgroundImageListShows { width: auto; height: 74px; background-size: contain; background-position: center center; background-repeat: no-repeat; }
.container-scroll { height: 750px; overflow-y: scroll; overflow-x: hidden }
.container-fluid-chosen-product-from-edit-shows .mat-card-header-text { width: 100%; }
/** front */
body {
  margin: 0;
  color: #fff;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('bg.jpg');
  min-height: 100%;
}
.btn-menu { background-color: var(--bgGray); }
.btn-menu-red { background-color: var(--bgRed); }
.btn-menu, .btn-menu-red { font-size: 11px; }
.container-banner img { border: 1px solid var(--borderRed) }
.container-banner .banner { background-image: url('banner.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover; border: 1px solid var(--borderRed); }
.container-banner .banner { height: 52vh }
.break { white-space: pre-wrap; }
.display-6 { font-size: 2rem; }
.opacity-0 { opacity: 0 }
.youtube-player { height: 447px; border: 1px solid var(--borderRed); }
.youtube-background-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.7);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 70px 70px;
}
.youtube-no-products {
  background-image: url('videocam_off-black-18dp.3574d2153f04bdad39fc.svg');
}
.youtube-play {
  background-image: url('play_circle_filled-black.f989c88d34e94a46ef59.svg');
}
.youtube-change-position {
  background-image: url('refresh-white-18dp.svg');
}
.youtube-play-again:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  background-image: url('loop-white-18dp.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 70px 70px;
}
.youtube-play-again:before {
  content: 'Odtwórz jeszcze raz.';
  position: absolute;
  left: 0;
  bottom: 30%;
  width: 100%;
  text-align: center;
  z-index: 3;
}
.youtube-no-products:after,
.youtube-change-position:after {
  position: absolute;
  left: 0;
  bottom: 30%;
  width: 100%;
  text-align: center;
}
.youtube-no-products:after {
  content: "Proszę dodać produkty, aby utworzyć pokaz.";
}
.youtube-change-position:after {
  content: "Wykryto zmiany, proszę zapisać.";
}
.btn-download-video, .btn-download-list-products { background-image: radial-gradient(#d93832,#780000); }
.btn-download-video, .btn-download-list-products, .btn-shared { box-shadow: inset 0 0 10px #000, 0 0 4px #fff; }
.btn-download-video, .btn-download-list-products, .btn-shared { line-height: 13px; height: 39px; font-size: 12px; position: relative }
.btn-download-video:after, .btn-download-list-products:after, .btn-shared:after {
  content: "";
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 26px;
  position: absolute;
  top: 0;
  right: 10px;
  height: 35px;
  width: 100%;
}
.btn-download-video:after { background-image: url('save_alt-white-18dp.c52f1252589c9673ba32.svg'); }
.btn-shared:after { background-image: url('facebook-white-18dp.d76d31fc2d7bd21b1894.svg'); height: 40px; }
.btn-download-list-products:after { background-image: url('playlist_add_check-white-18dp.b7a260033821ed0e434b.svg'); height: 38px; }
.btn-shared { background-image: radial-gradient(#4c86fa,#0851e5); }
#carousel-products-controls:focus { outline: none; }
.backgroundImageProductCarousel { width: auto; height: 120px; background-size: contain; background-position: center center; background-repeat: no-repeat; }
.backgroundImageCategory { width: auto; height: 200px; background-size: contain; background-position: center center; background-repeat: no-repeat; }
.backgroundImageCategoryCurrent { width: auto; height: 105px; background-size: contain; background-position: center center; background-repeat: no-repeat; }
.card-product {
  background-color: #171717;
  border-radius: 20px;
  border: 1px solid #fff;
}
.card-product .card-footer {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #2b2b2b !important;
}
.text-red { color: var(--bgRed); }
.card-cart, .card-return { background-color: #7a7a7a; border-radius: 12px; }
.card-cart:focus, .card-return:focus { outline: none; }
.more-action { position: absolute; bottom: 10px; right: 10px; }
.more-action button { width: 30px; height: 30px; }
.more-action .material-icons { position: relative; top: -4px !important; }
.btn-disable-button { line-height: 18px !important; }
.btn-save-change-cache {
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
}
/** slider range */
.ngx-slider {
 margin: 0 0 15px !important;
}
.ngx-slider .ngx-slider-bubble {
  cursor: default;
  bottom: -41px !important;
  padding: 1px 3px;
  color: #fff !important;
  font-size: 16px;
}
.ngx-slider .ngx-slider-pointer:focus {
  outline: none !important;
}
.ngx-slider .ngx-slider-pointer {
  cursor: pointer;
  width: 21px !important;
  height: 21px !important;
  top: -9px !important;
  background-image: radial-gradient(#9e9e9e,#4b4a4a)!important;
  border: 1px solid #fff!important;
  z-index: 3;
  border-radius: 16px;
}
.ngx-slider .ngx-slider-selection {
  z-index: 2;
  background: var(--bgRed) !important;
  border-radius: 2px;
}
.ngx-slider .ngx-slider-pointer:after {
  display: none !important;
}
/** slider range end */
.copyright-icons {
  position: relative;
  top: 7px;
}
.copyright-mobile .copyright-icons {
  font-size: 15px;
  top: 3px;
}
/** mobile */
@media screen and (min-width: 0px) and (max-width: 991px) {
  .youtube-player,
  .youtube-player iframe,
  .col-pop-up-product-mobile iframe {
    height: 47vw;
  }
  .col-pop-up-product-mobile {
    padding-left: 0;
    padding-right: 0;
  }
  .btn-save-change-cache {
    bottom: 12px;
  }
  .youtube-background-dark {
    background-size: 40px 40px;
  }
  .icon-return-to-show-mobile {
    position: relative;
    top: -2px !important;
  }
  .container-banner .banner {
    height: 24vh;
  }
}
.extended-menu-footer-mobile .container-xxl {
  left: 0;
  bottom: 0;
}
.extended-menu-footer-mobile .bg-menu {
  background-color: #7B7B7B;
}
.extended-menu-footer-mobile .card-title {
  font-size: 2vw;
  line-height: 3vw;
}
.extended-menu-footer-mobile .space {
  padding-top: 60vw;
}
.basic-menu-footer-mobile .container-xxl {
  left: 0;
  bottom: 0;
}
.basic-menu-footer-mobile .bg-menu {
  background-color: #7B7B7B;
}
.basic-menu-footer-mobile .card-title {
  font-size: 2vw;
  line-height: 3vw;
}
.basic-menu-footer-mobile .space {
  padding-top: 30vw;
}
.bottom-sheet-mobile-menu {
  background-color: #212529;
}
.pointer {
  cursor: pointer;
}
.btn-piromax-add-to-cart {
  border: 1px solid var(--bgRed) !important;
}
